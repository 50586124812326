import {
  Button,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Waypoint } from "./interfaces";
import { waypoints as testDataWaypoints } from "./testData";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      " & > *": {
        paddingRight: 16,
      },
      // marginRight: 0,
    },
    confirmButton: {
      paddingRight: 8,
      paddingBottom: 8,
    },
  })
);

export const DriverPage = (): JSX.Element => {
  const classes = useStyles();

  const [waypointOptions, setWaypointOptions] = useState<Waypoint[]>([]);
  const [start, setStart] = useState<Waypoint | null>(null);
  const [destination, setDestination] = useState<Waypoint | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const [selectedWaypoints, setSelectedWaypoints] = useState<Waypoint[]>([]);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  // Update waypointOptions and selected
  useEffect(() => {
    setWaypointOptions(testDataWaypoints);
  }, []);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const updateStartOrDest = (
    value: Waypoint | null,
    valueType: "start" | "destination"
  ) => {
    (valueType === "start" ? setStart : setDestination)(value);
    const otherSetter = valueType === "destination" ? setStart : setDestination;
    const otherValue = valueType === "destination" ? start : destination;
    if (otherValue?.id === value?.id) {
      otherSetter(null);
    }
    const newOptions = testDataWaypoints.filter(
      (waypoint) =>
        !(value && waypoint.id === value.id) &&
        !(otherValue && waypoint.id === otherValue.id)
    );
    const newSelectedWaypoints = selectedWaypoints.filter(
      (waypoint) => value && waypoint.id !== value.id
    );
    setWaypointOptions(newOptions);
    setSelectedWaypoints(newSelectedWaypoints);
  };

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={6}>
        <form className={classes.root}>
          <Autocomplete
            id="start-autocomplete"
            options={waypointOptions}
            getOptionLabel={(option) => option.label}
            // getOptionSelected={(option, value) => option.id === value.id}
            value={start}
            onChange={(event, value) => updateStartOrDest(value, "start")}
            renderInput={(params) => (
              <TextField
                {...params}
                id="start-input"
                label="Start"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Autocomplete
            id="destination-autocomplete"
            options={waypointOptions}
            getOptionLabel={(option) => option.label}
            // getOptionSelected={(option, value) => option.id === value.id}
            value={destination}
            onChange={(event, value) => updateStartOrDest(value, "destination")}
            renderInput={(params) => (
              <TextField
                {...params}
                id="start-input"
                label="Destination"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <KeyboardDateTimePicker
            margin="normal"
            minDate={new Date()}
            maxDate={new Date(new Date().getTime() + 1000 * 3600 * 24 * 30)}
            maxDateMessage="You can't make travel plans beyond one month."
            fullWidth
            id="date-picker-dialog"
            label="Date & Time"
            format="MM/dd/yyyy"
            value={selectedDate}
            inputVariant="outlined"
            onChange={(date) => setSelectedDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <Autocomplete
            multiple
            filterSelectedOptions
            openOnFocus
            id="waypoints-autocomplete"
            options={waypointOptions}
            getOptionLabel={(option) => option.label}
            value={selectedWaypoints}
            // getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, values) => {
              setSelectedWaypoints(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="start-input"
                label="Waypoints"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => setOpenSnackBar(true)}
              >
                Complete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Your route:</Typography>
        {((!start || !destination) && (
          <Typography variant="body1" color="secondary">
            You must first choose a start and a destination.
          </Typography>
        )) || (
          <Timeline>
            {[start, ...selectedWaypoints, destination].map(
              (waypoint, index, { length }) => (
                <TimelineItem>
                  {index === 0 && selectedDate && (
                    <TimelineOppositeContent>
                      {selectedDate.toLocaleDateString() ===
                      new Date().toLocaleDateString()
                        ? "Today"
                        : selectedDate
                            .toLocaleDateString()
                            .substr(
                              0,
                              selectedDate.toLocaleDateString().length - 5
                            )}
                      {" at "}
                      {selectedDate.toLocaleTimeString()}
                    </TimelineOppositeContent>
                  )}
                  <TimelineSeparator>
                    <TimelineDot />
                    {index !== length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    {waypoint?.label || "Waypoint"}
                  </TimelineContent>
                </TimelineItem>
              )
            )}
          </Timeline>
        )}
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="info">
          The app is not yet ready, this is just a template.
        </Alert>
      </Snackbar>
    </Grid>
  );
};
