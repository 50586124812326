import testData from "./testData.json";
import { Travel, Waypoint } from "./interfaces";

const travels: Travel[] = [];

testData.users.forEach((user, index) => {
  travels.push({
    id: index,
    driver: user,
    startDate: new Date(
      new Date().getTime() + Math.floor(Math.random() * (3600 * 1000 * 24 * 5))
    ),
    waypoints: ((): Waypoint[] => {
      const tempWaypoints: Waypoint[] = [];
      const numberOfWaypoint = Math.floor(Math.random() * 5) + 2;
      while (tempWaypoints.length < numberOfWaypoint) {
        const randWaypoint =
          testData.locations[
            Math.floor(Math.random() * testData.locations.length)
          ];
        if (
          !tempWaypoints.find((waypoint) => waypoint.id === randWaypoint.id)
        ) {
          const { id, city: label, district: group } = randWaypoint;
          tempWaypoints.push({ id, label, group });
        }
      }
      return tempWaypoints;
    })(),
  });
});

travels.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

export { travels };

export const waypoints: Waypoint[] = testData.locations.map((loca) => ({
  id: loca.id,
  label: loca.city,
  group: loca.district,
}));
