import {
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Link,
  LinearProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Search from "@material-ui/icons/Search";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import { Travel } from "./interfaces";
import { travels } from "./testData";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const TEMP_LINK = "https://valentincharoux.com";

export const LifterPage = (): JSX.Element => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState("");
  const [searchStrDisplay, setSearchStrDisplay] = useState("");
  const [extendedTravel, setExtendedTravel] = useState<number | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const searchFilter = useMemo((): Travel[] => {
    let filtered: Travel[] = [];
    if (!searchStr.length) {
      return filtered;
    }
    filtered = travels.filter(
      (travel) =>
        travel.driver.name.toUpperCase().includes(searchStr.toUpperCase()) ||
        !!travel.waypoints.find((waypoint) =>
          waypoint.label.toUpperCase().includes(searchStr.toUpperCase())
        )
    );
    return filtered;
  }, [searchStr]);

  const [waitTimeoutId, setWaitTimeoutId] = useState<number | null>(null);
  // Time to wait before launching the search
  const inputSensitivity = 500;

  const handleInput = (str: string) => {
    setSearchStrDisplay(str);
    if (waitTimeoutId != null) {
      window.clearTimeout(waitTimeoutId);
    }
    setWaitTimeoutId(
      window.setTimeout(() => {
        setLoading(true);
        window.setTimeout(() => {
          setSearchStr(str);
          setLoading(false);
        }, Math.floor(Math.random() * 1000) + 200);
        setWaitTimeoutId(null);
      }, inputSensitivity)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="Search"
          variant="outlined"
          value={searchStrDisplay}
          onChange={(event) => {
            handleInput(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {loading && <LinearProgress color="secondary" />}
        {(!!searchFilter.length &&
          searchFilter.map((travel) => {
            const { driver, waypoints, startDate } = travel;
            const [start] = waypoints.length >= 1 ? waypoints : [undefined];
            const end =
              waypoints.length >= 1
                ? waypoints[waypoints.length - 1]
                : undefined;
            return (
              <Accordion
                key={travel.id}
                expanded={extendedTravel === travel.id}
                onChange={(event, expended) => {
                  setExtendedTravel(!expended ? undefined : travel.id);
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {driver.name}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {new Date().toLocaleDateString() ===
                    travel.startDate.toLocaleDateString()
                      ? "Today"
                      : travel.startDate
                          .toLocaleDateString()
                          .substring(
                            0,
                            travel.startDate.toLocaleDateString().length - 5
                          )}{" "}
                    {travel.startDate.toLocaleTimeString()}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {start && end && (
                      <>
                        <b>{start.label}</b>
                        {" to "}
                        <b>{end.label}</b>
                      </>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography>
                        <Link target="_blank" href={TEMP_LINK}>
                          Facebook
                        </Link>
                      </Typography>
                      <Typography>
                        <Link target="_blank" href={TEMP_LINK}>
                          Tel
                        </Link>
                      </Typography>
                      <Typography>
                        <Link target="_blank" href={TEMP_LINK}>
                          Email
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Timeline>
                        {waypoints.map((waypoint, index, { length }) => (
                          <TimelineItem key={waypoint.id}>
                            {index === 0 && startDate && (
                              <TimelineOppositeContent>
                                {startDate.toLocaleDateString() ===
                                new Date().toLocaleDateString()
                                  ? "Today"
                                  : startDate
                                      .toLocaleDateString()
                                      .substr(
                                        0,
                                        startDate.toLocaleDateString().length -
                                          5
                                      )}
                                {" at "}
                                {startDate.toLocaleTimeString()}
                              </TimelineOppositeContent>
                            )}
                            <TimelineSeparator>
                              <TimelineDot />
                              {index !== length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              {waypoint?.label || "Waypoint"}
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })) || (
          <Grid container>
            <Grid item>
              <Typography variant="h3" color="error">
                <Search
                  style={{ fontSize: 100, transform: "translateY(30%)" }}
                  color="error"
                />
                Search for some rides
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
