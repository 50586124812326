import { Container, Grid, Box, Tab, Tabs } from "@material-ui/core";
import React from "react";
import "./App.css";
import { Header } from "./Components/Header";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DriverPage } from "./Components/DriverPage";
import { LifterPage } from "./Components/LifterPage";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function App() {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => {
                setSelectedTab(value);
              }}
            >
              <Tab label="Driver" icon={<DirectionsCarIcon />} />
              <Tab label="Lift" icon={<PersonPinIcon />} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={selectedTab} index={0}>
              <DriverPage />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <LifterPage />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

export default App;
